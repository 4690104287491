@import url('https://fonts.googleapis.com/css2?family=PT+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0a192f !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px;
  color: #ccd6f6;
  line-height: 1.1;
}

p {
  /* margin: 2rem 0 0; */
  font-family: 'Open Sans', sans-serif;
  color: #8892b0;
  max-width: 740px;
}

/* @media (min-width: 768px) {
  p {
    font-size: 1.5rem;
  }
} */