.landing-page {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: 0 150px;
}
.top__btn {
  position: fixed;
  bottom: 6rem;
  right: 23px;
  background-color: transparent;
  outline: none;
  border: none;
  height: fit-content;
  width: fit-content;
  color: #64ffda;
}

.top__btn svg {
  font-size: 3rem;
  animation: animateTopBtn 2s ease-in-out infinite;
}

@keyframes animateTopBtn {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(5px);
  }

  60% {
    transform: translateY(3px);
  }
}

.landing-page h1 {
  margin: 6rem 0 15px 4px;
  font-family: "PT Mono", monospace;
  color: #64ffda;
  font-size: clamp(14px, 5vw, 20px);
}
.landing-page h2 {
  color: #ccd6f6;
}
.landing-page h3 {
  margin-top: 10px;
  color: #8892b0;
  line-height: 0.9;
}

.big-heading {
  margin: 0 0 10px;
  font-weight: 800;
  font-family: "PT Mono", monospace;
  font-size: clamp(26.67px, 6vw, 80px);
}
.email-wrapper {
  writing-mode: vertical-rl;
  letter-spacing: 0.3px;
  font-family: "PT Mono", monospace;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  right: 40px;
  bottom: 0;
}
.email-wrapper a {
  margin: 20px auto;
  padding: 10px;
  text-decoration: none;
  font-size: 0.9rem;
  color: #8892b0;
  cursor: pointer;
  letter-spacing: 0.7px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.email-wrapper a:hover {
  color: #64ffda;
  letter-spacing: 0.8px;
  transform: scaleY(1.02);
}

.email-wrapper::after,
.social-media-wrapper::after {
  content: " ";
  display: block;
  width: 1px;
  height: 90px;
  margin: 0 auto;
  position: relative;
  background: #a8b2d1;
  animation: animateLine 2s ease-in-out alternate;
}

@keyframes animateLine {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}
.social-media-wrapper {
  writing-mode: vertical-lr;
  letter-spacing: 0.3px;
  font-family: "PT Mono", monospace;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  left: 40px;
  bottom: 0;
}

.social-media-wrapper a {
  padding: 10px;
  cursor: pointer;
  margin: 15px auto;
}

.social-media-wrapper svg:hover {
  color: #64ffda;
  transform: scale(1.3);
}

.social-media-wrapper svg {
  color: #8892b0;
  width: 20px;
  height: 20px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media (max-width: 992px) {
  .landing-page {
    padding: 100px 100px 0;
  }
}

@media (max-width: 768px) {
  .landing-page {
    padding: 0 50px;
  }
  .email-wrapper,
  .social-media-wrapper {
    display: none;
  }
}
@media (max-width: 480px) {
  .landing-page {
    padding: 0 24px;
  }
}
