

::-webkit-scrollbar {
    width: 6px;
    background: #0a192f;
}

::-webkit-scrollbar-thumb {
    background-color: #8892b0;
    border-radius: 0.58rem;
}

.form-control {
    border: 1px solid #64ffda;

}

.form-control:focus {
    border: 1px solid #64ffda;
    box-shadow: 0 0 1.5px 3px rgba(100, 255, 218, 0.9);
}
.form-control.is-invalid {
    padding-right: 0 !important;
    background-image: none;
}