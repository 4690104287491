
.navbar {
    margin: 0;
    padding: 0 !important; 
    background-color: rgba(10, 25, 47, 0.85) !important;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.home__navbar {
    z-index: 999;
    position: fixed;
    top: 0; 
    left: 0; 
    width: 100%;
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 30px -10px rgba(2, 12, 27, 0.7) !important;
}

.home__navbar.hidden {
    transform: translateY(-100%);
}

.offcanvas-header .btn-close{
    border: 1px solid #64ffda;
    color: rgba(100, 255, 218, 0.4) !important; 
    background: transparent url("../../Assets/Images/close-btn.svg");
    background-position: 6px 6px;
    background-repeat: no-repeat;
    background-size: contain;
}

.btn-close:focus {
    box-shadow: 0 0.125rem 0.50rem rgba(100, 255, 218, 0.5) !important;
    
}
    
.offcanvas {
    background-color: rgba(10, 25, 47, 1) !important;
}


.navbar-toggler {
    border: 1px solid #64ffda !important;
    color: rgba(100, 255, 218, 0.4) !important;
}

.navbar-toggler-icon {
    background-image: url("../../Assets/Images/hamburger.svg") !important;
}

.navbar-nav .nav-link.active {
    color: #64ffda;
    font-size: .97rem;
}

.navbar-nav .nav-link {
    color: #ccd6f6 !important;
    font-family: 'PT Mono', monospace;
    font-size: .95rem;
    letter-spacing: 1px;
    text-decoration: none !important;
    cursor: pointer;
}


.navbar-nav .nav-link.nav-last {
    margin-right: .5rem !important;
}
.navbar-nav .nav-link:hover {
    color: #64ffda !important;
}

.navbar-nav .nav-link span {
    font-size: 0.9rem;
    color: #64ffda !important;
    margin-right: 3px;
}

.resume-btn {
    text-decoration: none !important;
    color: #64ffda !important;
}


.offcanvas-body{
    display: flex;
    justify-content: center;
    align-items: center;
}

.offcanvas.offcanvas-end {
    width: min(75vw, 400px);
}

@media (max-width: 991px){
    .offcanvas-body .navbar-nav .nav-link span{
        display: block;
        margin: 5px auto;
    }
}
@media (max-width: 768px){
    .resume-btn-wrapper {
        margin-top: 1rem;
    }
}

