.footer__social__media__wrapper {
    margin: 4rem auto .5rem;
}

.footer__social__media__wrapper a {
    padding: 10px;
    cursor: pointer;
}

.footer__social__media__wrapper svg:hover {
    color: #64ffda;
    transform: scale(1.3);
}

.footer__social__media__wrapper svg {
    color: #8892b0;
    width: 20px;
    height: 20px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

footer > p {
    margin: 1rem auto;
    font-weight: 500;
}

@media (min-width: 769px) {
    .footer__social__media__wrapper {
        display: none;
    }

    footer {
        margin: 5rem auto 1.5rem;
    }

}